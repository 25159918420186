import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

const Nijntje = () => (
  <StaticQuery
    query={graphql`
      query {
        nijntje1: file(relativePath: { eq: "nijntje-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nijntje2: file(relativePath: { eq: "nijntje-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nijntje3: file(relativePath: { eq: "nijntje-3.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        nijntje4: file(relativePath: { eq: "nijntje-4.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Nijntje museum | Charlotte de Hilster</title>
        </Helmet>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 45%;
            grid-column-gap: 24px;
            width: 100%;
            @media only screen and (max-width: 600px) {
              grid-template-columns: 100%;
            }
          `}
        >
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 2;
              }
            `}
          >
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.nijntje1.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.nijntje2.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.nijntje3.childImageSharp.fluid}
            />
            <Img fluid={data.nijntje4.childImageSharp.fluid} />
          </div>
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 1;
              }
            `}
          >
            <h3
              css={css`
                font-family: "Lato", sans-serif;
                font-weight: bold;
                font-size: 32pt;
                @media only screen and (max-width: 600px) {
                  font-size: 24pt;
                }
              `}
            >
              Nijntje museum responsive website
            </h3>
            <p
              style={{
                fontFamily: `Lato, sans-serif`,
                fontWeight: `lighter`,
                fontSize: `14pt`
              }}
            >
              Voorheen was het Dick Bruna huis onderdeel van het Centraal Museum
              en had het ook een plekje op hun website. Het nijntje museum kwam
              een aantal jaren geleden los te staan van het Centraal Museum.
              Reden genoeg voor een nieuwe website.
              <br />
              <br />
              Het concept voor de website is als volgt:
              <br />
              Spelenderwijs de wereld van nijntje en haar vriendjes ontdekken
              <br />
              <br />
              Samen met de art director heb ik het concept voor de website
              bedacht. De kracht van dit concept is dat de website moet laten
              zíen dat het nijntje museum niet zomaar een museum is, waar je de
              boekjes van nijntje kunt lezen. Dat kan iedereen thuis immers ook
              doen. Het is belangrijk dat de website laat zien dat het museum
              een ontdekkingsreis biedt aan het kind. Een ontdekkingsreis samen
              met nijntje en haar vriendjes.
              <br />
              <br />
              De uitdaging die ik tijdens dit project heb gekregen is de rol van
              lead UX designer. Daardoor was ik niet alleen verantwoordelijk
              voor de wireframes maar ik ook voor het bedenken van het concept,
              de doorvertaling van het concept in de wireframes, het overbrengen
              van het concept en de wireframes aan een comité van het Centraal
              Museum. Ook tijdens de verdere ontwikkeling van de website ben ik
              continu betrokken geweest.
              <br />
              <br />
              <a
                css={css`
                  color: #59bfa9;
                `}
                href="https://nijntjemuseum.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nijntjemuseum.nl
              </a>
            </p>
          </div>
        </div>
      </Layout>
    )}
  />
);

export default Nijntje;
